import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "@reach/router";

import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/Layout";
import Flex from "../components/Flex";
import styles from "../styles/pages/privacidade.module.scss";
import { useIntl } from "gatsby-plugin-intl"
import SEO from "../components/SEO";

const PrivacyPage: FunctionComponent<RouteComponentProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(
        relativePath: { eq: "backgrounds/hamburger.svg" }
      ) {
        publicURL
      }
      yellowDots: file(
        relativePath: { eq: "backgrounds/yellow-dots.svg" }
      ) {
        publicURL
      }
    }
  `);
   const intl = useIntl();
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "seo_title_privacidade" })}
        description={intl.formatMessage({ id: "seo_description_privacidade" })}
        keywords={intl.formatMessage({ id: "seo_keywords_privacidade" })}
        canonical_url={`https://take-eat.pt/privacidade`}
      />
      <Flex flexDirection="row" alignItems="start" justifyContent="between" className={styles.termsPage}>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="start"
          className={styles.imageCol}
        >
          <img src={data.yellowDots.publicURL} alt="yellow dots image" className={styles.termsImageLeft}/>
        </Flex>

        <Flex flexDirection="column" alignItems="start" className={styles.termsCol}>
          <h1 className={styles.termsTitle}> {intl.formatMessage({id:"PrivacyPage_title"})}</h1>
          <p className={styles.termsText}>
            {intl.formatMessage({id:"PrivacyPage_paragraph_one"})}
          </p>
          <p className={styles.termsText}>
            {intl.formatMessage({id:"PrivacyPage_paragraph_two"})}
          </p>
          <p className={styles.termsText}>
            {intl.formatMessage({id:"PrivacyPage_paragraph_three"})}
          </p>
          <p className={styles.termsText}>
            {intl.formatMessage({id:"PrivacyPage_paragraph_four"})}
          </p>
          <p className={styles.termsText}>
            {intl.formatMessage({id:"PrivacyPage_paragraph_five"})}
          </p>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="end"
          className={styles.imageCol}
        >
          <img src={data.background.publicURL} alt="background image" className={styles.termsImageRight} />
        </Flex>
      </Flex>
    </Layout>
  )
};

export default PrivacyPage;
